import { Grid, Row, Caption, H4, Tag } from "@maggioli-design-system/react";
import React from "react";
import ILibro from "./ILibro";
import BookCover from "./BookCover";

interface CustomBook {
    iLibro: boolean;
}

const FreeBook = ({ iLibro }: CustomBook): JSX.Element => {
    const btnIcon: string = iLibro ? "logo-ilibro" : "";
    const [show, setShow] = React.useState<boolean>(false);

    return (
        <Grid gutter="small" rows="fit-vertically">
            <Row gutter="small" className="items-start">
                {/* Immagine del libro */}
                <BookCover />
                {/* Info libro */}
                <Grid gutter="xsmall" className="py-3 ml-5">
                    {/* <Link key={data} to={`/dettaglio/${bookSlug}`} onClick={onClickLibro}> */}
                    <H4 className="text-xl">PNRR</H4>
                    {/* </Link> */}
                    <Grid gutter="xxsmall">
                        <Caption>
                            <strong>Autori:</strong> Maria Carla Manca, Rosa Ricciardi
                        </Caption>
                        <Caption>Marzo 2022</Caption>
                    </Grid>
                </Grid>
            </Row>
            <Row>
                <Tag icon={btnIcon} chip className="cursor-pointer hover:background-color-brand-maggioli-18" onClick={() => setShow(true)}>
                    <Caption>Consulta il volume</Caption>
                </Tag>
            </Row>
            {/* Modale iLibro */}
            <ILibro show={show} setShow={setShow} isbn={"9788891655547"} title={"PNNR"} cache={Date.now()} viewer=""/>
        </Grid>
    );
};

export default FreeBook;
