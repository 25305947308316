import React, { ReactElement, useState } from 'react'
import {
    Grid,
    Select,
    H5,
} from '@maggioli-design-system/react'

interface Props {
    selectOrderBy: Function,
}

export default function OrderBy({ selectOrderBy }: Props): ReactElement {

    const [orderBy, setOrderBy] = useState<string>("");

    const setSelectOrderBy = (value: string) => {
        setOrderBy(value);
        selectOrderBy(value);
    }

    return (
        <Grid gutter="xsmall">
            <H5>Ordina per</H5>
            <Select data={['Pertinenza', 'Data']} value={orderBy} 
                    onChange={(e) => setSelectOrderBy(e.target.value)}/>
        </Grid>
    )
}
