import {
    Grid,
    InputText,
    Caption,
    Modal,
    Icon,
    H5,
    Tag
} from '@maggioli-design-system/react'
import {BookLabel} from "./Book";
import BookCover from './BookCover';
import { Link, useNavigate } from "react-router-dom";
import { getName } from '../lib/Months';
import { expressURL } from '../lib/Constants';
import Singleton from '../lib/Singleton';
import { IBook } from '../Interfaces/IBook';
import { areas } from './AreasGrid';

interface ISearchResultOccurrence {
    book: IBook,
    searchID: string,
    setViewer: Function,
    setShow: Function,
    setIsbn: Function,
    setShowModal: Function,
    data: string,
}

const openBook = async (id: string, searchID: string, searchParams: string = "") => {
    const res = await fetch(`${expressURL}/api/documents/open/${id}/${searchID}${searchParams}`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${Singleton.getActiveToken()}`,
        },
    })
    if(!res.ok) {
        return false;
    }
    return await res.json();
}

const SearchResultOccurrence = ({ data, book, searchID, setViewer, setShow, setIsbn, setShowModal }: ISearchResultOccurrence): JSX.Element => {

    const highlightTitle: string =  data.split("</div>")[1];
    const highlighPage: string = data.split("data-value='")[1].split("'")[0];
    const highlighPagePF: string = data.split("data-href='")[1].split("'")[0];

    const onClick = async () => {
        const openedBook = await openBook(book.id, searchID, `?startFrom=${highlighPagePF.replace("#", "")}`);
        if(!openedBook || !openedBook.data.hasAccessToExtraContent) {
            setShowModal(true);
            return;
        }
        setViewer(openedBook.data.viewer);
        setShow(true);
        setIsbn(book.isbn)
    }

    return <div className="flex gap-2 items-start rounded-md group hover:bg-adjust-tone-19 p-1 transition-colors cursor-pointer" onClick={() => onClick()}>
        <div className="flex-shrink-0 bg-adjust-tone-18 group-hover:bg-adjust-tone px-2 py-0.5 rounded inline-flex gap-2 items-center transition-colors min-w-[60px]">
            <Icon name="document-book" size="small" />
            <Caption className="flex-grow text-center">{highlighPage}</Caption>
        </div>
        <Caption className="mt-[2px]"><p dangerouslySetInnerHTML={{ __html: highlightTitle }}></p></Caption>
    </div>
}

const SearchResult = ({ data, hasAttach, setAttachModal, setShowModal, searchID, ...restProps }): JSX.Element => {
    const book: IBook = data.libro;
    const isNew = book.mese >= new Date().getMonth() && book.anno === new Date().getFullYear();
    const bookSlug: string = `${book.name.replaceAll(" ", "-").replaceAll("/", "-").replaceAll(",", "-")}-${book.isbn}`;
    const history = useNavigate();

    const onClickLibro = () => {
        sessionStorage.setItem("scrollPage", window.scrollY.toString());
        sessionStorage.setItem("book", JSON.stringify(book));
    };

    const onClickAttachments = async () => {
        const openedBook = await openBook(data.id, searchID);
        if(!openedBook || !openedBook.data.hasAccessToExtraContent) {
            setShowModal(true);
            return;
        }
        setAttachModal({ book: book, visible: true });
    }

    return (
        <div className="grid search-result">
            <div className="relative">
                <div className="sticky top-0">
                    <Link className="min-w-8 relative overflow-visible rounded-md" to={`/dettaglio/${bookSlug}`} onClick={onClickLibro}>
                        {isNew && <BookLabel className="bg-label-green-06 absolute top-2 right-2 z-10">Novità</BookLabel>}
                        {/* <BookLabel className="bg-adjust-tone-06 absolute bottom-2 right-2 z-10">Funzioni AI</BookLabel> */}
                        <BookCover data={book} />
                    </Link>
                </div>
                { hasAttach && <Tag
                    icon="additional-contents"
                    chip
                    className="cursor-pointer hover:background-color-brand-maggioli-17 mt-2"
                    onClick={() => onClickAttachments()}
                /> }
                { book.aipenDocumentiId !== undefined && <Tag
                    icon="ai-brain"
                    chip
                    className="cursor-pointer hover:background-color-brand-maggioli-17 mt-2"
                    onClick={() => history(`/dettaglio/${bookSlug}`)}
                /> }
            </div>
            <div className="grid gap-4">
                <Link className="min-w-8 relative overflow-visible rounded-md" to={`/dettaglio/${bookSlug}`} onClick={onClickLibro}>
                    <H5>{book.name}</H5>
                </Link>
                <Caption className="-mt-3">
                    <strong>Autori:</strong>{" "}
                    { book.autori.join(", ").length > 30 ? `${book.autori.join(", ").slice(0, 30)}...` : book.autori.join(", ") } <br />
                    {/* <strong>Aree:</strong>{" "}
                    { book.categoria !== undefined && book.categoria.map((c, i) => {
                        const includedAreas = areas.filter(a => a.areaLabel.includes(c))[0];
                        if (includedAreas === undefined) {
                            return;
                        }
                        return <span key={i} className={includedAreas.color}>{c} </span>
                    }) } */}
                    <br />
                    {`${getName(book.mese)} ${book.anno}`}
                </Caption>
                <div className="grid gap-2">{restProps.children}</div>
            </div>
        </div>
    );
};

interface SearchProps {
    visible: boolean,
    setVisible?: Function,
}

const Search = ({visible, ...restProps}): JSX.Element => {

    return (
        <Modal maxWidth="700" maxHeight className="items-start pt-4" visible={visible} footer={false} position={"center"}>
            <Grid>
                <InputText label="cerca qualcosa" placeholder="Es: Ufficio tecnico"></InputText>
                <div className="grid gap-0">
                    { restProps.children }
                </div>
            </Grid>
        </Modal>
    )
}

export {
    Search,
    SearchResult,
    SearchResultOccurrence,
}