import React, { Fragment } from "react";
import * as BookService from "../services/Books";
import { Button, Detail, Download, Grid, Paginator, Modal, UList, UListItem } from "@maggioli-design-system/react";
import { TailSpin } from "react-loader-spinner";

interface IRedazionale {
    modalShow?: boolean;
    setModalShow?: Function;
    idTree?: number;
    title?: string;
    isbn?: number;
}

export interface ITree {
    idTree?: number;
    setShowDocuments?: Function;
    setId?: Function;
    provvedimento?: boolean;
    setProvvedimento?: Function;
    startPage?: number;
    isbn?: number;
}

interface ITreeChild {
    id?: number;
    voce?: string;
    hasChildren?: boolean;
    docCount?: number;
}

interface IDocument {
    id?: number;
    did?: number;
    descrizione?: string;
    sottotitolo: string;
    descrReda: string;
    type?: string;
    oggetto?: string;
    titolo?: string;
    estensione?: string;
    ftype: string;
}

const Redazionale = ({ modalShow, setModalShow, idTree, title, isbn }: IRedazionale): JSX.Element => {
    const [showDocuments, setShowDocuments] = React.useState<boolean>(false);
    const [id, setId] = React.useState<number>();
    const [provvedimento, setProvvedimento] = React.useState<boolean>(false);
    const [menu, setMenuOpen] = React.useState<boolean>(false);

    return (
        <Modal
            className={"modal-index"}
            classNameContents="overflow-hidden relative"
            contentOnly
            footer={false}
            header={false}
            maxHeight
            maxWidth
            onCancel={() => setModalShow(false)}
            position={"center"}
            title={title}
            visible={modalShow}
        >
            <div className="lg:hidden">
                <Button
                    onClick={() => setMenuOpen(!menu)}
                    icon={`${menu ? "action-close" : "menu-main"}`}
                    className="absolute z-20 top-2 right-2 p-0 text-adjust-tone-04 bg-transparent rounded-none"
                    horizontalPadding="none"
                />
            </div>
            <div className="gap-0 grid--template-attachments relative block h-screen lg:grid ">
                <div
                    className={`bg-adjust-tone pb-48 overflow-y-scroll max-h-screen transition-transform | lg:col-auto lg:static col-span-full z-10 absolute inset-0 ${
                        menu ? "" : "-translate-y-full lg:translate-y-0"
                    }`}
                >
                    <Tree idTree={idTree} setId={setId} setShowDocuments={setShowDocuments} setProvvedimento={setProvvedimento} />
                </div>
                <div className="bg-adjust-tone-20 pb-48 p-8 overflow-y-auto max-h-screen transition-transform | lg:col-auto col-span-full">
                    <Grid rows="fit-vertically">
                        {showDocuments && (
                            <GetDocuments idTree={id} provvedimento={provvedimento} setProvvedimento={setProvvedimento} setId={setId} startPage={1} />
                        )}
                    </Grid>
                </div>
            </div>
        </Modal>
    );
};

const Tree = ({ idTree, setId, setShowDocuments, setProvvedimento }: ITree): JSX.Element => {
    const [child, setChild] = React.useState<ITreeChild[]>([]);

    React.useEffect(() => {
        if (idTree) {
            BookService.getChildrenTree(idTree, (data: any, headers: any) => {
                if (data === 404) {
                    console.log("Error load child");
                } else {
                    setChild(data);
                }
            });
        }
    }, [idTree]);

    const openDoc = (id: number) => {
        setProvvedimento(false);
        setId(id);
        setShowDocuments(true);
    };

    const loop = child.map((v: ITreeChild, i: number) => (
        <Fragment key={i}>
            {!v.hasChildren ? (
                <div onClick={() => openDoc(v.id)}>
                    <UListItem
                        className="hover:bg-brand-maggioli-19 hover:text-brand-maggioli-06 cursor-pointer transition-colors p-2 rounded"
                        icon="document"
                        iconClassName="text-brand-maggioli-08"
                    >
                        {v.voce}
                    </UListItem>
                </div>
            ) : (
                <UListItem className="p-2" icon="data-view" iconClassName="text-brand-maggioli-08">
                    <span className="block">{v.voce}</span>
                    {v.hasChildren && <Tree idTree={v.id} setId={setId} setShowDocuments={setShowDocuments} setProvvedimento={setProvvedimento} />}
                </UListItem>
            )}
        </Fragment>
    ));

    return (
        <UList className="p-2" text="text-secondary text-secondary--detail">
            {loop}
        </UList>
    );
};

const GetDocuments = ({ idTree, provvedimento, setProvvedimento, setId }: ITree): JSX.Element => {
    const [documents, setDocuments] = React.useState<IDocument[]>([]);
    const [page, setPage] = React.useState<number>(1);
    const [size] = React.useState<number>(20);
    const [maxPage, setMaxPage] = React.useState<number>();
    const [i, setI] = React.useState<number>();
    const [loading, setLoading] = React.useState<boolean>(false);

    console.log(documents)

    React.useEffect(() => {
        if (idTree !== i) {
            setPage(1);
            setMaxPage(0);
            setI(idTree);
        }
        if (idTree && !provvedimento) {
            setLoading(true);
            BookService.getRedaDocuments(idTree, page - 1, size, (data: any, total: number) => {
                if (data === 404) {
                    console.log("Error load child");
                } else {
                    setDocuments(data.data);
                    setMaxPage(Math.ceil(total / size));
                }
                setLoading(false);
            });
        } else if (idTree && provvedimento) {
            setLoading(true);
            BookService.getProvvedimenti(idTree, page - 1, size, (data: any, total: number) => {
                if (data === 404) {
                    console.log("Error load child");
                } else {
                    setDocuments(data.data);
                    setMaxPage(Math.ceil(total / size));
                }
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idTree, size, page, provvedimento, i]);

    const downloadDocument = (id: number, name: string) => {
        setLoading(true);
        BookService.downloadDocument(id, (blob: Blob) => {
            setLoading(false);
            /* if (
                window.navigator &&
                window.navigator.msSaveOrOpenBlob
                ) return window.navigator.msSaveOrOpenBlob(blob); */

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = data;
            link.download = name;

            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                }),
            );

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        });
    };

    const getProvvedimento = (id: number) => {
        setProvvedimento(true);
        setId(id);
    };

    const loop = documents.map((v: IDocument, i: number) => (
        <Grid key={i} className="gap-2">
            <div>
                <Detail className="max-w-paragraph">{v.type === "provvedimento" ? v.titolo : v.oggetto}</Detail>
            </div>
            <div>
                {v.type !== "provvedimento" ? (
                    v.hasOwnProperty("ftype") ? (
                        <Grid className="gap-1">
                            <Detail>{v.descrizione}</Detail>
                            <div>
                                <Download
                                    onClick={() => downloadDocument(v.id, `${v.descrReda}.${v.ftype.toLowerCase()}`)}
                                    fileName={`${v.descrReda}.${v.ftype.toLowerCase()}`}
                                    className="lg:w-auto lg:inline-flex"
                                />
                            </div>
                        </Grid>
                    ) : (
                        <Detail>Il documento cercato non è più presente nei nostri archivi.</Detail>
                    )
                ) : (
                    <Button variant="secondary-outline" icon="data-search" onClick={() => getProvvedimento(v.id)}>
                        {v.descrizione}
                    </Button>
                )}
            </div>
        </Grid>
    ));

    return (
        <Grid className="gap-12">
            {maxPage !== 1 && maxPage >= page && (
                <Paginator
                    pages={maxPage}
                    currentPage={page}
                    onClick={(newPage: number) => setPage(newPage)}
                    onClickPrev={() => page > 1 && setPage(page - 1)}
                    onClickNext={() => page < maxPage && setPage(page + 1)}
                />
            )}

            {!loading ? loop : <TailSpin color="#0041B9" />}
        </Grid>
    );
};

export default Redazionale;
