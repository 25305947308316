import { Link } from "react-router-dom";
import CategoryCard from "../components/CategoryCard";
import { Grid } from "@maggioli-design-system/react";
import { IProdotto } from "../Interfaces/IProdotto";

export interface IAreas {
    id: string;
    to?: string;
    category?: string;
    src?: string;
    color?: string;
    areaLabel: string[];
}

export const areas: IAreas[] = [
    {
        id: "208",
        to: "/categoria/lm-amministrazione/208/1",
        category: "Amministrazione e PA digitale",
        src: `${process.env.PUBLIC_URL}/assets/images/lm-amministrazione.jpg`,
        color: "bg-area-administration-18 text-area-administration-04",
        areaLabel: ["Amministrazione e PA Digitale"],
    },
    {
        id: "202",
        to: "/categoria/lm-appalti/202/1",
        category: "Appalti e Contratti",
        src: `${process.env.PUBLIC_URL}/assets/images/lm-appalti.jpg`,
        color: "bg-area-public-procurement-18 text-area-public-procurement-04",
        areaLabel: ["Appalti e Contratti"],
    },
    {
        id: "203",
        to: "/categoria/lm-bilancio/203/1",
        category: "Bilancio, Contabilità e Tributi",
        src: `${process.env.PUBLIC_URL}/assets/images/lm-bilancio.jpg`,
        color: "bg-area-accounting-18 text-area-accounting-04",
        areaLabel: ["Bilancio e Contabilità", "Tributi"],
    },
    {
        id: "204",
        to: "/categoria/lm-personale/204/1",
        category: "Personale e Organizzazione",
        src: `${process.env.PUBLIC_URL}/assets/images/lm-personale.jpg`,
        color: "bg-area-personal-18 text-area-personal-04",
        areaLabel: ["Personale e organizzazione"],
    },
    {
        id: "205",
        to: "/categoria/lm-polizia/205/1",
        category: "Polizia e Attività economiche",
        src: `${process.env.PUBLIC_URL}/assets/images/lm-polizia.jpg`,
        color: "bg-area-police-18 text-area-police-04",
        areaLabel: ["Polizia", "Attività economiche"],
    },
    {
        id: "207",
        to: "/categoria/lm-demografici/207/1",
        category: "Servizi Demografici",
        src: `${process.env.PUBLIC_URL}/assets/images/lm-demografici.jpg`,
        color: "bg-area-demographic-18 text-area-demographic-04",
        areaLabel: ["Servizi Demografici"],
    },
    {
        id: "206",
        to: "/categoria/lm-ufficio-tecnico/206/1",
        category: "Ufficio Tecnico",
        src: `${process.env.PUBLIC_URL}/assets/images/lm-ufficio-tecnico.jpg`,
        color: "bg-adjust-tone-18 text-adjust-tone-04",
        areaLabel: ["Ufficio Tecnico"],
    },
];

interface IProps {
    prodotti: any[];
}

const AreasGrid = ({ prodotti }: IProps): JSX.Element => {
    return (
        // Griglia delle categorie
        <Grid template="auto-fit-large" gutter="large">
            {prodotti
                .filter((p) => areas.map((a) => a.id).includes(p.id.toString()))
                .map((v: IProdotto, i: number) => {
                    const area = areas.filter((a) => a.id === v.id.toString()).pop();
                    return (
                        <Link key={i} to={area.to}>
                            <CategoryCard category={area.category} className={`h-full ${area.color}`} src={area.src} />
                        </Link>
                    );
                })}
        </Grid>
    );
};

export default AreasGrid;
