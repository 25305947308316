import React, { useState } from 'react'
import {
    Detail,
    Icon,
    Card,
    Grid,
} from '@maggioli-design-system/react'
import { Link } from 'react-router-dom'

interface IILibroCard {
    className?: string,
}

const ILibroCard = ({ className = "" }: IILibroCard): JSX.Element => 
    
    <Card className={className}>

        {/* Descrizione allegati */}
        <div className='mb-3'>
            <div>
                <Icon name="ai-brain" size="xlarge"/>
                <Detail><b>AI generativa</b></Detail>
            </div>
            <Detail>Sistema AI rapido e intuitivo per interrogare i contenuti dei volumi con domande in linguaggio naturale.</Detail>
            <Detail className="underline cursor-pointer text-label-blue-03">
                <Link to="/ai-policy">Consulta la nostra policy AI</Link>
            </Detail>
        </div>

        {/* Descrizione iLibro */}
        <div className='mb-3'>
            <img alt="ilibro logo" className="h-12 mb-2" loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/iLibro.svg`}/>
            <Detail><b>iLibro</b> 
            {/* è la versione digitale del libro cartaceo arricchita con le seguenti funzionalità: */}
            </Detail>
            <Detail>Collegamento ipertestuale alla documentazione (normativa, prassi, giurisprudenza) citata nel volume, costantemente aggiornata e in versione multivigenza.</Detail>
        </div>

        {/* Descrizione allegati */}
        <div>
            <img alt="approfondimenti logo" className="h-12 mb-2" loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/logo-contenuti-aggiuntivi.svg`}/>
            <Detail><b>Contenuti aggiuntivi</b></Detail>
            <Detail>Consultazione di tutti i materiali online integrativi collegati ai volumi (schemi di atti, modulistica, flow-chart, video, ecc.)</Detail>
        </div>

        {/* Alert */}
        <div>
            <Icon name="warning"/>
            <Detail><b>Attenzione</b></Detail>
            <Detail>Le suddette funzionalità potrebbero non essere presenti in alcuni volumi e possono comunque essere soggette a revoca da parte dell’Editore.</Detail>
            <Detail>Inoltre, nella versione iLibro è possibile che non sia attivo il collegamento ipertestuale per alcuni provvedimenti di normativa, prassi e giurisprudenza.</Detail>
        </div>
    </Card>

interface IFilterYear {
    data: string[],
    selectYear: Function,
}

// Filtro per anno
const FilterYear = ({ data, selectYear }: IFilterYear): JSX.Element => {

    const years: string[] = [ "Tutti", ...data ];

    const [year, setYear] = useState<string>('Tutti');

    const setSelectYear = (value: string) => {
        setYear(value);
        selectYear(value);
    }
                                              
    if(data.length > 0) {
        return (
            <Grid gutter="xsmall">
                <label htmlFor="select-year" className="-mb-2 text-base uppercase font-bold">Anno</label>
                <div className='select__item'>
                    <select className="select__field text-secondary text-secondary--detail" id="select-year" 
                            onChange={(e) => setSelectYear(e.target.value)} value={year}>
                        { years.map((y, i) => <option key={i} className="select__option " value={y}>{y}</option> )}
                    </select>
                    <i className="icon icon--size-normal select__icon mgg-icons-navigation-show"></i>
                </div>
            </Grid>
        )
    } else {
        return ;
    }
    
}

export {ILibroCard, FilterYear}