import React, { useState } from 'react'
// import clsx from 'clsx'
import { Card, H4, Icon } from '@maggioli-design-system/react'

const AccordionItem = ({ className = "", opened = false, ...restProps }) => {

    const [ isOpened, toggle ] = useState(opened)

    return <div onClick={() => { toggle(!isOpened) }} className={className + ' accordion-item grid gap-0 py-4'}>
        <div className="flex gap-4 items-center justify-between cursor-pointer">
            <H4 className="flex-grow">{ restProps.title }</H4>
            <Icon className={`flex-shrink-0 transition-transform rotate-0 duration-300 ease-in-out ' ${isOpened && 'rotate-180'})`} name="form-dropdown" />
        </div>
        <div className={`grid accordion-item__content ' ${isOpened ? 'accordion-item__content--opened pt-4' : 'pt-0'}`}>
            <div className="overflow-hidden">
                { restProps.children }
            </div>
        </div>
    </div>
}

const Accordion = ({ className = "", ...restProps }) =>
    <div className={className + ' grid gap-0'}>
        { restProps.children }
    </div>


export {
    Accordion,
    AccordionItem,
}
