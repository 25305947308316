import { Button, Detail, Form, Grid, InputText, Radio, Textarea } from "@maggioli-design-system/react";
import * as Constants from "../lib/Constants";
import { ChangeEvent, useEffect, useState } from "react";
import { ILoader } from "../Interfaces/ILoader";
import { ISubmitBtn } from "../Interfaces/ISubmitBtn";
import { ThreeDots } from "react-loader-spinner";
import * as Privacy from "../services/Privacy";

interface IContactForm {
    ip: string;
    footerMessage: string;
    loader: ILoader;
    mailSubject: string;
    requestType: string;
}

interface IFormField {
    elem: IField;
}

interface IFormFields {
    form: IField[];
}

interface IHiddenFields {
    form: IField[];
}

interface IOptions {
    value: string;
    text: string;
}

interface IField {
    name: string;
    label: string;
    type: string;
    placeholder: string;
    options: IOptions;
}

interface IPrivacy {
    description: string;
    fields: IField[];
}

interface IForm {
    privacy: IPrivacy[];
    fields: IField[];
    servizio: string;
    consenso_informato: string;
}

// Canpo input del form
const FormField = ({ elem }: IFormField): JSX.Element => (
    <InputText name={elem.name} label={elem.label} type={elem.type} placeholder={elem.placeholder} />
);

// Campo nascosto del form
const HiddenField = ({ elem }: IFormField): JSX.Element => <input name={elem.name} value={elem.options.value} type={elem.type} />;

// Lista dei campi input del form
const FormFields = ({ form }: IFormFields): JSX.Element => (
    <>
        {form
            .filter((elem) => elem.type !== "hidden")
            .map((elem, i) => (
                <FormField key={i} elem={elem} />
            ))}
    </>
);

// Lista dei campi nascosti del form
const HiddenFields = ({ form }: IHiddenFields): JSX.Element => (
    <>
        {form
            .filter((elem) => elem.type === "hidden")
            .map((elem, i) => (
                <HiddenField key={i} elem={elem} />
            ))}
    </>
);

const token: string =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJwcml2YWN5Lm1hZ2dpb2xpY2xvdWQuaXQiLCJhdWQiOjEsImlhdCI6MTYxOTQ0OTQzNiwibmJmIjoxNjE5NDQ5NDM2LCJleHAiOjE5MzQ4MDk0MzYsImRhdGEiOnsiaWQiOjY2LCJzaXRlX2lkIjo5NH19.UHOpVIfEBgjcWcx6_NacfTDyMzHJANGYu6SBYNqN5m8";

const ContactForm = ({ ip, loader, mailSubject, footerMessage = "", requestType }: IContactForm): JSX.Element => {
    const [form, setForm] = useState<IForm>(null);
    const [submit, setSubmit] = useState<ISubmitBtn>({ status: "", text: "Invia" });

    const [message, setMessage] = useState<string>("");
    const [commerciale, setCommerciale] = useState<string>(null);

    const [sendingMail, setSendingMail] = useState<boolean>(false);

    const checkErrors: boolean = commerciale !== null;
    
    useEffect(() => {
        getPrivacy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPrivacy = async () => {
        if (form !== null) {
            return;
        }
        await Privacy.getForm().then((result) => {
            setForm(result.data.form[0]);
        });
    };

    const privacyHandler = (value: string, privacyName: string) => {
        switch (privacyName) {
            case "commerciale":
                setCommerciale(value);
                break;
            default:
                break;
        }
    };

    // Campi consensi privacy
    const privacyFields = (): JSX.Element[] => {
        return form.privacy.map((elem: IPrivacy, index: number) => (
            <div key={index}>
                <Detail className="privacy-consent" htmlTag={"div"}>
                    <p dangerouslySetInnerHTML={{ __html: elem.description }} />
                    {elem.fields.map((item: IField, i: number) => (
                        <Radio
                            key={i}
                            name={item.name}
                            value={item.options.value}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => privacyHandler(e.target.value, item.name)}
                        >
                            {item.options.text}
                        </Radio>
                    ))}
                </Detail>
            </div>
        ));
    };

    const onClickSendRequest = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const privacyForm: FormData = new FormData(e.target);
        const data: any = {};
        for (const [key, value] of privacyForm.entries()) {
            data[key] = value;
        }
        data["token"] = token;
        data['ip'] = ip;

        const res = await fetch(`${Constants.privacyURL}/api/v1/post/send`, {
            method: "POST",
            body: JSON.stringify(data),
        });

        const result = await res.json();
        if (!result.status) {
            setSubmit({ status: "error", text: "Errore nel salvataggio del consenso." });
            window.setTimeout(() => setSubmit({ status: "", text: "Invia" }), 2500);
            return;
        }

        const formData = new FormData(e.target);
        const payload = {
            to: "clienti.editore@maggioli.it", // email amministrazione
            from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Biblioteca Digitale", //nome sito
            replyTo: formData.get("email"), //email inserita dall'utente
            subject: mailSubject,
            message: `<b>Info utente</b> <br/>Nome: ${formData.get("nome")}<br/>Cognome: ${formData.get("cognome")}
                        <br/>Email: ${formData.get("email")}<br/><br/>
                        <b>Messaggio</b><p>${formData.get("message")}</p><br/>${footerMessage}`,
            responseParams: {
                to: formData.get("email"), //email inserita dall'utente
                message: Constants.mailText(requestType),
            },
            categories: ["no-reply-sitiweb@maggiolicloud.it", "bibliotecadigitale", "contatti"],
        };

        if (submit.status !== "success") {
            setSendingMail(true);
            const res = await fetch(`${Constants.expressURL}/api/email/send`, {
                method: "POST",
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
            const data = await res.json();
            if (!res.ok) {
                setSubmit({ status: "error", text: data.message });
                window.setTimeout(() => setSubmit({ status: "", text: "Invia" }), 2500);
                return;
            }
            setSubmit({
                status: "success",
                text: "Richiesta inviata con successo!",
            });
            window.setTimeout(() => (window.location.href = "/thank-you?gtm=" + requestType), 1000);
        }
    };

    return (
        !loader.visible && (
            <Grid gutter="xsmall">
                <Grid gutter="small">
                    {form !== null && (
                        <Form onSubmit={(e: ChangeEvent<HTMLFormElement>) => onClickSendRequest(e)}>
                            {/* Campi form presi da Privacy */}
                            <Grid columns="2" gutter="small">
                                <FormFields form={form.fields} />
                            </Grid>

                            {/* Campi nascosti da Privacy */}
                            <HiddenFields form={form.fields} />

                            {/* Servizio */}
                            <input name="servizio" value={form.servizio} type="hidden" />

                            {/* Consenso informato trattamento */}
                            <input name="trattamento" value="1" type="hidden" />

                            {/* Testo del messaggio */}
                            <Textarea
                                value={message}
                                name="message"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
                                label="Richiedi informazioni"
                                placeholder="Es: Vorrei chiedere informazioni su un preventivo e individuare un agente per la mia zona."
                            />

                            {/* Consenso informato */}
                            <Detail htmlTag={"div"}>
                                <p className="privacy-consent" dangerouslySetInnerHTML={{ __html: form.consenso_informato }} />
                            </Detail>

                            {/* Campi privacy */}
                            {privacyFields()}

                            {/* Bottone invia */}
                            {!sendingMail ? (
                                <Button icon="file-email" type="submit" disabled={!checkErrors} variant={submit.status}>
                                    {submit.text}
                                </Button>
                            ) : (
                                <div className="view-limit">
                                    <ThreeDots color="#0041B9" />
                                </div>
                            )}
                        </Form>
                    )}
                </Grid>
            </Grid>
        )
    );
};

export default ContactForm;
