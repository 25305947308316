import axios from "axios";
import { expressURL, getCookie } from "../lib/Constants";
import { getAccessToken } from "../lib/Functions";

// restituisce le Q&A di un libro da AIpen (se ci sono)
export const getQAInBook = async (aipenID: string): Promise<any> => {
    const token = await getAccessToken();
    const config: Object = {
        method: "get",
        url: `${expressURL}/api/aipen/qa/${aipenID}`,
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };
    return await axios(config);
};

export const askQuestionJob = async (payload: any, aipenID: string): Promise<any> => {
    const token = await getAccessToken();
    const config: Object = {
        method: "POST",
        url: `${expressURL}/api/aipen/question/${aipenID}`,
        data: JSON.stringify(payload),
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
    };

    return await axios(config);
};

export const checkUser = async (): Promise<any> => {
    const token = await getAccessToken();
    const config: Object = {
        method: "POST",
        url: `${expressURL}/api/aipen/check-user`,
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
    };

    return await axios(config);
};