import React from 'react'

export default function VideoTutorial() {
  return <div style={{ position: "relative" }}>
    <iframe title="Biblioteca video tutorial" src="https://player.vimeo.com/video/1030781808?badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}></iframe>
  </div>
  return (
    <div className="flex justify-center">
        <iframe src="https://www.youtube-nocookie.com/embed/eR4Xsm8pK1g" title="YouTube video player" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen className="box-border video-tutorial max-w-[600px]" width="100%" height="100%"/>
    </div>
  )
}