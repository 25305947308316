import { Link } from "react-router-dom";
import { Caption, Detail, Grid, HeaderLogo, Image, Row } from "@maggioli-design-system/react";

import Redazionale from "../../components/Redazionale";
import { scrollTop } from "../../lib/Functions";
import { ILoader } from "../../Interfaces/ILoader";
import { IRedaHandler } from "../../Interfaces/IRedaHandler";
import { privacyURL } from "../../lib/Constants";
import { TailSpin } from "react-loader-spinner";

interface IFooterBiblioteca {
    setMatch: Function;
    loader: ILoader;
    redazionale: IRedaHandler;
}

const FooterBiblioteca = ({ setMatch, loader, redazionale }: IFooterBiblioteca): JSX.Element => {
    const CURRENT_YEAR: number = new Date().getFullYear();
    const PRIVACY_URL: string = `${privacyURL}/privacy/sso.maggioli.it`;

    const deselectCategory = () => {
        setMatch({ url: "/", params: {} });
        scrollTop();
    };

    const loaderComponent = (): JSX.Element => (
        <>
            <div className="bg-pinello" />
            <div className="pinello">
                <TailSpin color="#0041B9"/>
            </div>
        </>
    );

    return (
            <footer className="background-color-adjust-tone">
                {/* Redazionale se c'è */}
                {redazionale.tree !== undefined && (
                    <Redazionale
                        modalShow={redazionale.redaShow}
                        setModalShow={redazionale.setRedaShow}
                        idTree={redazionale.tree.id}
                        title={redazionale.tree.voce}
                        isbn={redazionale.isbn}
                    />
                )}

                {/* Loader */}
                {loader.visible && loaderComponent()}

                <Grid className="view-limit py-16 px-8" columns="3">
                    {/* Prima colonna del footer */}
                    <div>
                        <Grid gutter="xsmall">
                            <HeaderLogo src={`${process.env.PUBLIC_URL}/assets/images/logo-biblioteca-digitale.png`} alt="logo biblioteca digitale" />
                            <Caption className="mt-4">
                                <strong>
                                    Biblioteca Digitale è un servizio del <br />
                                    Gruppo Maggioli
                                </strong>
                            </Caption>
                            <Caption>
                                Via del Carpino, 8<br />
                                47822 Santarcangelo di Romagna (RN) Italia
                            </Caption>
                        </Grid>
                    </div>

                    {/* Seconda colonna del footer */}
                    <div>
                        <Grid gutter="xsmall">
                            <Detail><strong>Servizio clienti</strong></Detail>
                            <Caption>
                                Disponibile dal Lunedì al Venerdì
                                <br />
                                Dalle 08:30 alle 17:30
                            </Caption>
                            <Caption>Tel. +39 0541 628200</Caption>
                            <Detail className="color-brand-maggioli-03">
                                <Link to={"/contatti"} onClick={() => scrollTop()}>
                                    Contattaci
                                </Link>
                            </Detail>
                        </Grid>
                    </div>

                    {/* Terza colonna del footer */}
                    <div>
                        <Grid gutter="xsmall">
                            <Detail>
                                <a href="/informativa-sui-cookies">Cookie policy</a>
                            </Detail>
                            <Detail>
                                <a href={PRIVACY_URL} target="_blank" rel="noreferrer">
                                    Privacy policy
                                </a>
                            </Detail>
                            <Detail>
                                <Link to={"/condizioni-di-servizio"} onClick={() => deselectCategory()}>
                                    Condizioni generali del contratto
                                </Link>
                            </Detail>
                            <Detail>
                                <Link to={"/ai-policy"} onClick={() => deselectCategory()}>
                                    Istruzioni per l’uso dell’IA
                                </Link>
                            </Detail>
                            <Image alt="logo gruppo maggioli" className="w-16" src={`${process.env.PUBLIC_URL}/assets/images/logo-gruppo-maggioli.svg`} />
                        </Grid>
                    </div>
                </Grid>

                {/* Barra del footer */}
                <div className="background-color-brand-maggioli-04 color-brand-maggioli-20">
                    <Row className="view-limit p-4">
                        <Caption className="m-auto">
                            Copyright 2003-{CURRENT_YEAR} Maggioli Spa - P.Iva 02066400405 - Iscritta al R.E.A. di Rimini al n. 219107
                        </Caption>
                    </Row>
                </div>
            </footer>
    );
};

export default FooterBiblioteca;
