import React, { useEffect, Fragment, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Grid, Row, H4, Icon, H2, Image } from "@maggioli-design-system/react";

import AreasGrid from "../components/AreasGrid";
import VideoTutorial from "../components/VideoTutorial";
import ExampleBook from "../components/ExampleBook";
import { accordionSections, getCookie, MIN_LENGTH_SEARCH, setCookie } from "../lib/Constants";
import { checkIP, enableIP, introspect, scrollTop } from "../lib/Functions";
import { Keycloak } from "keycloak-auth";
import Singleton from "../lib/Singleton";
import { IProdotto } from "../Interfaces/IProdotto";

interface Props {
    searchText: string;
    setShowSearch: Function;
    ip: string;
    prodotti: IProdotto[];
}

const images: string[] = [
    `${process.env.PUBLIC_URL}/assets/images/digital-book.svg`,
    `${process.env.PUBLIC_URL}/assets/images/visualizzatore.png`,
    `${process.env.PUBLIC_URL}/assets/images/ai.png`,
    `${process.env.PUBLIC_URL}/assets/images/ilibro.png`,
    `${process.env.PUBLIC_URL}/assets/images/contenuti.png`,
    `${process.env.PUBLIC_URL}/assets/images/ricerca.png`,
    `${process.env.PUBLIC_URL}/assets/images/videoricerca.mov`,
];

const Home = ({ searchText, setShowSearch, ip, prodotti }: Props): JSX.Element => {
    const history = useNavigate();
    const idProdotti: string[] = prodotti.map((p) => p.id.toString());

    const noipCheck = localStorage.getItem("noIP");
    const ipEnabled = localStorage.getItem("ipEnabled");
    const checkIPCookie = getCookie("checkIP");

    const refreshToken = getCookie("refresh_token");

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const code: string = queryParameters.get("code");
    const [showBanner, setShowBanner] = useState<boolean>(noipCheck === null && checkIPCookie !== null && refreshToken === null);
    const [accordionSelected, setAccordionSelected] = useState<number>(0);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    useEffect(() => {
        function handleEnterPress(key: string) {
            const searchbar: any = window.document.getElementById("searchbar");
            if (key !== "Enter" || searchbar === null) {
                return;
            }
            if (searchbar.value.length >= MIN_LENGTH_SEARCH) {
                history(`/cerca/1/${searchbar.value}`);
            } 
        }
        window.addEventListener("keypress", e => handleEnterPress(e.key));

        return () =>  {
            window.removeEventListener("keypress", e => handleEnterPress(e.key));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        function handleAccordionIndex(index: number) {
            setAccordionSelected(index);
        }

        window.document.addEventListener("mdsAccordionTimerChange", (e: any) => handleAccordionIndex(e.detail.index))

        return () => window.document.removeEventListener("mdsAccordionTimerChange", (e: any) => handleAccordionIndex(e.detail.index))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        function handleScroll() {
            if(accordionElement.hasAttribute("scrolling")) {
                return;
            }

            const scrollY = window.scrollY;
            if (scrollY > 100) {
                accordionElement.setAttribute("scrolling", "true");
                setIsScrolled(true);
            }
        }

        const accordionElement = window.document.getElementById("accordion-timer");
        if (accordionElement === null) {
            return;
        }

        window.document.addEventListener('scroll', handleScroll)

        return () => window.document.removeEventListener("scroll", handleScroll)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        login();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `Biblioteca digitale Maggioli`;
        setShowSearch(true);

        return () => setShowSearch(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshTokens = async (code: string, refresh: boolean = false) => {
        const dataKey = await Keycloak.auth().getToken(code, refresh);
        if (!dataKey.access_token) {
            return false;
        }

        return dataKey.access_token;
    };

    const login = async () => {
        if (!code && refreshToken !== null) {
            return;
        }
        if (!code && refreshToken === null) {
            checkLoginSSO();
            return;
        }
        const accessToken: string = Singleton.getActiveToken();
        if (!accessToken) {
            return;
        }
        const data: string = refreshToken !== null ? refreshToken : code;
        const refreshedToken = await refreshTokens(data, refreshToken !== null);
        if (!refreshedToken) {
            return;
        }
        const decodedToken = await introspect(refreshedToken);
        if (!decodedToken) {
            return;
        }
        if (ipEnabled === null && checkIPCookie !== null) {
            const res = await enableIP(ip, accessToken, decodedToken.preferred_username, decodedToken.sub, idProdotti);
            if (!res) {
                return;
            }
            localStorage.setItem("ipEnabled", "ipEnabled");
        }
        window.location.href = "/";
    };

    const checkLoginSSO = async () => {
        if (noipCheck !== null || checkIPCookie !== null) {
            return;
        }

        const guestToken = Singleton.getActiveToken();
        if (!guestToken) {
            return;
        }
        const check = await checkIP(ip, guestToken, idProdotti);
        if (check === null) {
            return;
        }
        if (!check) {
            localStorage.setItem("noip", "noip");
            return;
        }

        setCookie("checkIP", "checkIP", 1800);
        setShowBanner(true);
    };

    const onClickHideBanner = () => {
        setShowBanner(false);
    };

    const onClickRegisterLogin = () => {
        window.location.replace(Keycloak.auth().createLoginString());
    };

    return (
        <Fragment>
            {showBanner && (
                <div className="bg-status-warning-18 p-10">
                    <div className="view-limit">
                        <Row lastChild="to-right">
                            <H4>
                                Per continuare ad accedere ai contenuti del sito registrati con il tuo indirizzo email di lavoro. Successivamente
                                potrai accedere con le credenziali in possesso. Se sei già registrato clicca qui sotto su Accedi.
                            </H4>
                            <Icon name="crud-cancel" className="cursor-pointer" onClick={() => onClickHideBanner()} />
                        </Row>
                        <Button className="mt-3" onClick={() => onClickRegisterLogin()}>
                            Registrati / Accedi
                        </Button>
                    </div>
                </div>
            )}

            <div className="p-4 text-adjust-tone-20 bg-gradient-to-t from-brand-maggioli-07 to-brand-maggioli-03 min-h-[500px] flex items-center">
                <Grid className="view-limit" columns="2">
                    <div className="flex items-center justify-center p-6" id="accordion-timer">
                        <mds-accordion-timer duration={7000}>
                            { accordionSections.map((s, i) => 
                            <mds-accordion-timer-item description={s.title} selected={i === 0} class="text-adjust-tone">
                                <mds-text>{s.text}</mds-text>
                            </mds-accordion-timer-item>)}
                            {/* <mds-accordion-timer-item description="Ricerca (video)" class="text-adjust-tone">
                                <mds-text>
                                    Motore di ricerca generale per parole e concetti, che consente di trovare rapidamente i contenuti di interesse all’interno di tutti i volumi della Biblioteca.
                                </mds-text>
                            </mds-accordion-timer-item> */}
                        </mds-accordion-timer>
                    </div>
                    {/* Immagine libro home */}
                    <div className="flex items-center justify-center p-6">
                        <div className="w-full aspect-square max-h-[400px] flex items-center justify-center">
                            { !accordionSections[accordionSelected].image.includes("mov") ?
                             <Image alt="Illustrazione di un libro digitale" className="w-full" src={images[accordionSelected]} />
                             : 
                             <video autoPlay src={accordionSections[accordionSelected].image} loop/> 
                             }
                        </div>
                    </div>
                </Grid>
            </div>


            {/* Griglia aree tematiche */}
            <div className="px-8 py-16 bg-adjust-tone-19">
                <div className="view-limit mb-16">
                    <H2 className="text-center vertical-padding-large mb-10">Le aree tematiche di Biblioteca Digitale</H2>
                    <AreasGrid prodotti={prodotti} />
                </div>
                <H2 className="text-center mb-6">Scopri le funzionalità</H2>
                <div className="grid gap-4 grid-cols-2 max-w-5xl m-auto">
                    <VideoTutorial />
                    <ExampleBook />
                </div>
                            
                <Link to="/servizio" onClick={() => scrollTop()}>
                    <Button className="justify-start mx-auto mt-10" icon="document-book" variant="secondary-outline" >
                        Scopri il servizio
                    </Button>
                </Link>
            </div>
        </Fragment>
    );
};

export default Home;
