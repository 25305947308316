import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Banner, Grid, H5, Tag, Checkbox } from "@maggioli-design-system/react";

import * as AttachService from "../services/Attachments";
import * as BookService from "../services/Books";
import Attachments from "../components/Attachments";
import ILibro from "../components/ILibro";
import { ILibroCard, FilterYear } from "../components/Sidebar";
import { scrollTop, serverResponseOK } from "../lib/Functions";
import { IBook } from "../Interfaces/IBook";
import { IYear } from "../Interfaces/IYear";
import { ILoader } from "../Interfaces/ILoader";
import { IProdotto } from "../Interfaces/IProdotto";
import { IRedaHandler } from "../Interfaces/IRedaHandler";
import { SearchResult, SearchResultOccurrence } from "../components/Search";
import { expressURL, getCookie, MIN_LENGTH_SEARCH } from "../lib/Constants";
import OrderBy from "../components/OrderBy";
import Singleton from "../lib/Singleton";
import { areas, IAreas } from "../components/AreasGrid";
import { IHasAttach, IModal } from "./Category";

interface ISearchPage {
    setShowModal: Function;
    loader: ILoader;
    redazionale: IRedaHandler;
    idProdotti?: IProdotto[];
    setSearchText: Function;
}

const SearchPage = ({ setShowModal, loader, idProdotti, redazionale, setSearchText }: ISearchPage): JSX.Element => {

    const userIsLogged: boolean = getCookie("access_token") !== null && getCookie("refresh_token") !== null;
    const { page, query }: any = useParams();
    const history = useNavigate();

    const [modal, setModal] = useState<IModal>({ visible: false, book: null });
    const [show, setShow] = useState<boolean>(false);
    const [isbn, setIsbn] = useState<string>("");
    const [searchID, setSearchID] = useState<string>("");
    const [viewer, setViewer] = useState<string>("");
    const [checkAttach, setCheckAttach] = useState<IHasAttach[]>([]);
    const [years, setYears] = useState<string[]>([]);
    const [selectedYear, setSelectedYear] = useState<string>("Tutti");
    const [orderBy, setOrderBy] = useState<string>("Pertinenza");
    const [prodottiAttivi, setProdottiAttivi] = useState<string[]>([]);
    const [prodottiAttiviSelected, setProdottiAttiviSelected] = useState<IAreas[]>([]);
    const [endResult, setEndResult] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [searchedBooks, setSearchedBooks] = useState<IBook[]>([]);

    useEffect(() => {
        if(firstLoad) {
            setFirstLoad(false);
            return;
        }
        searchBooks();
    }, [prodottiAttiviSelected])

    useEffect(() => {

        document.title = `Cerca ${query} | Biblioteca digitale Maggioli`;
        getYears();
        loadProdottiAttivi();
        setSearchText(query);

        function handleEnterPress(key: string) {
            const searchbar: any = window.document.getElementById("searchbar");
            if (key !== "Enter" || searchbar === null) {
                return;
            }
            if (searchbar.value.length >= MIN_LENGTH_SEARCH) {
                searchBooks(0, searchbar.value);
                setFirstLoad(true);
            } else {
                history("/categoria/lm-amministrazione/208/1");
            }
        }
        window.addEventListener("keypress", e => handleEnterPress(e.key));

        return () =>  {
            window.removeEventListener("keypress", e => handleEnterPress(e.key));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        searchBooks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear, orderBy]);

    const loadProdottiAttivi = async () => {
        if (!userIsLogged) {
            return;
        }
        const accessToken = Singleton.getActiveToken();
        const res = await fetch(`${expressURL}/api/users/profile`, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id_prodotti: idProdotti.map((p) => p.id).join(",") }),
        });
        if (!res.ok) {
            return;
        }
        const data = await res.json();
        const subProducts = [];
        data.forEach((p) => {
            subProducts.push(
                ...p.sub_products.map((sp) => sp.id_prodotto)
            );
        });
        setProdottiAttivi([...data.map(p => p.id_prodotto), ...subProducts]);
    };

    const scrollDown = () => {
        const y = sessionStorage.getItem("scrollPage");
        if (y !== null && parseInt(y) > 0) {
            window.scrollTo({
                top: parseInt(y),
                behavior: "smooth",
            });
            sessionStorage.removeItem("scrollPage");
        }
    };

    // Controlla se ci sono contenuti aggiuntivi su Approfondimenti per i volumi passati
    const checkAttachments = (data: IBook[], page: number) => {
        AttachService.checkAttachments(data.map((elem) => elem.isbn).toString())
            .then((result) => {
                if (serverResponseOK(result)) {
                    setCheckAttach((oldArray) => (page > 0 ? [...oldArray, ...result.data.data] : result.data.data));
                }
            })
            .catch((e) => console.log(e));
    };

    // Restituisce gli anni di pubblicazione dei libri dal Redazionale
    const getYears = () => {
        BookService.getRedaBookYears().then((result) => {
            if (serverResponseOK(result)) {
                setYears(result.data.map((elem: IYear) => elem.key));
            }
        });
    };

    // Cerca i libri sul redazionale
    const searchBooks = async (newPage = 0, text: string = query) => {
        loader.setLoader(true); 
        history(`/cerca/${newPage + 1}/${text}`);
        BookService.newSearchBooks(text, newPage, orderBy, selectedYear, prodottiAttiviSelected.map(p => p.areaLabel).flat()).then((result) => {
            if (serverResponseOK(result) && result.data.data.length > 0) {
                checkAttachments(result.data.data.map(d => d.libro), newPage);
            }
            setSearchedBooks((oldArray) => (newPage > 0 ? [...oldArray, ...result.data.data] : result.data.data))
            setSearchID(result.data.searchId);
            setEndResult(result.data.data.length < 10);
            loader.setLoader(false);
            newPage !== 0 ? scrollDown() : scrollTop();
        }).catch((e) => console.log(e));
    };

    // Titolo del volume da mostrare nella modale dell'iLibro
    const bookTitle = (): string => {
        const book: IBook = searchedBooks.filter((elem) => elem.isbn === isbn)[0];
        return book === undefined ? "" : book.formati !== undefined && book.formati.includes("iLibro") ? `iLibro - ${book.name}` : book.name;
    };

    const onClickArea = (productSelected: IAreas) => {
        if(prodottiAttiviSelected.filter(p => p.category === productSelected.category).length > 0) {
            setProdottiAttiviSelected(prodottiAttiviSelected.filter(p => p.category !== productSelected.category));
        } else {
            setProdottiAttiviSelected([ ...prodottiAttiviSelected, productSelected ])
        }
    }

    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            <div className="view-limit pt-8 pb-0 lg:pb-8">
                <Grid template="category" gutter="xlarge">
                    <div className="hidden lg:block">
                        <Grid className="sticky top-32">
                            {/* filtro per anni */}
                            {<FilterYear data={years} selectYear={(v) => setSelectedYear(v)} />}

                            <OrderBy selectOrderBy={(v: string) => setOrderBy(v)} />
                            
                            {prodottiAttivi.length > 0 && 
                            <Grid gutter="xsmall">
                                <H5>Aree</H5>
                                { areas.filter(a => prodottiAttivi.includes(a.id)).map((a, i) => {
                                    return <Checkbox key={i} isChecked={prodottiAttiviSelected.map(p => p.category).includes(a.category)} type="checkbox" 
                                                        onChange={() => onClickArea(a)}>
                                                {a.category}
                                    </Checkbox>
                                })}
                            </Grid>}
                            
                            {/* Sidebar */}
                            <ILibroCard/>
                        </Grid>
                    </div>
                    <Grid rows="fit-vertically">
                        {/* Banner errore no libri da mostrare */}
                        { searchedBooks.length === 0 && !loader.visible && (
                            <Grid rows="fit-vertically">
                                <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail">
                                    Non sono stati trovati volumi per la ricerca effettuata.
                                </Banner>
                            </Grid>
                        )}

                        { searchedBooks.map((b, i) => {
                            const hasAttach: boolean = checkAttach.length === searchedBooks.length && checkAttach[i].has_contents;
                            return (
                                <SearchResult key={i} data={b} hasAttach={hasAttach} setAttachModal={setModal} searchID={searchID} setShowModal={setShowModal}>
                                    {b.highlights.map((h, ii) => {
                                        return <SearchResultOccurrence 
                                                    key={ii}
                                                    data={h} 
                                                    book={b} 
                                                    searchID={searchID} 
                                                    setIsbn={setIsbn}
                                                    setViewer={setViewer}
                                                    setShowModal={setShowModal}
                                                    setShow={setShow}
                                                />
                                    })}
                                </SearchResult>
                            );
                        }) }
                    </Grid>
                </Grid>
                <div className="text-center -mx-58 lg:mx-0 py-0 pt-8 lg:py-24">
                    {!endResult ? (
                        // bottone per caricare più risultati
                        <Tag chip className="cursor-pointer background-color-adjust-tone-18 hover:background-color-adjust-tone-15"
                            onClick={() => searchBooks(parseInt(page))}>
                            <H5>Carica più risultati...</H5>
                        </Tag>
                    ) : (
                        // bottone per caricare più risultati
                        <Tag chip className="cursor-pointer background-color-adjust-tone-18 hover:background-color-adjust-tone-15" onClick={() => scrollTop()}>
                            <H5>Torna su</H5>
                        </Tag>
                    )}
                </div>

                {/* Sidebar */}
                <ILibroCard className="md:grid lg:hidden my-8" />

                {/* Modale iLibro */}
                <ILibro show={show} setShow={setShow} isbn={isbn} title={bookTitle()} cache={Date.now()} viewer={viewer} />

                {/* Modale allegati */}
                <Attachments redazionale={redazionale} loader={loader} key={modal.book ? modal.book.isbn : 0} book={modal.book}
                    visible={modal.visible} hideModal={() => setModal((p) => ({ ...p, visible: false }))}
                />
            </div>
        </div>
    );
};

export default SearchPage;
