import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Accordion, AccordionItem } from '../components/Accordion';
import { Banner, Button, Caption, Code, Detail, InputText, Grid, H5, H3, Modal, Hr, H2, Row, Icon } from "@maggioli-design-system/react";

import Attachments from "../components/Attachments";
import ILibro from "../components/ILibro";
import * as BookService from "../services/Books";
import * as AttachService from "../services/Attachments";
import * as Months from "../lib/Months";
//import clsx from 'clsx'
import { IBook } from "../Interfaces/IBook";
import { ILoader } from "../Interfaces/ILoader";
import { IProdotto } from "../Interfaces/IProdotto";
import { IRedaHandler } from "../Interfaces/IRedaHandler";
import BookCover from "../components/BookCover";
import { BookLabel } from "../components/Book";
import AIGenerated from "../components/AIGenerated";
import { askQuestionJob, checkUser, getQAInBook } from "../services/AIpen";
import { getCookie, searchbar } from "../lib/Constants";
import ReactMarkdown from "react-markdown"
import { areas } from "../components/AreasGrid";

interface IBookPage {
    setShowModal: Function;
    loader: ILoader;
    redazionale: IRedaHandler;
    idProdotti?: IProdotto[];
}

export interface IQuestion {
    question: string,
    answer: string,
}

export interface IFaq {
    domanda: string,
    risposta: string,
    pubblicata: boolean
}

const BookPage = ({ setShowModal, loader, idProdotti, redazionale }: IBookPage): JSX.Element => {
    const { slug } = useParams();
    const userIsLogged: boolean = getCookie("refresh_token") !== null;
    const history = useNavigate();

    const [show, setShow] = useState<boolean>(false);
    const [modalVisibile, setModalVisibile] = useState<boolean>(false);

    const [showIndex, setShowIndex] = useState<boolean>(false);
    const [showFAQs, setShowFAQs] = useState<boolean>(false);
    const [book, setBook] = useState<IBook>(null);
    const [index, setIndex] = useState<string>("");
    const [hasAttach, setHasAttach] = useState<boolean>(false);
    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [generating, setGenerating] = useState<boolean>(false);
    const [newQuestion, setNewQuestion] = useState<string>("");
    
    const [faqs, setFaqs] = useState<IFaq[]>([]);
    const [overview, setOverview] = useState<string>("");
    const [aipenID, setAipenID] = useState<string>("");
    const [infoText, setInfoText] = useState<boolean>(false);
    const [copy, setCopy] = useState<boolean>(false);
    const [placeholder, setPlaceholder] = useState<string>("Fai una domanda alla IA inerente al volume...");


    useEffect(() => {
        uploadTitle();
        loadBook();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        faqs.length > 0 && setPlaceholder(faqs[0].domanda);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faqs]);

    const loadBook = async () => {
        loader.setLoader(true);
        const isbn: string = slug.split("-").pop();

        const resultBook = await BookService.getRedaBook(isbn);
        if (!resultBook.status) {
            return;
        }
        setBook(resultBook.data);

        const resultAttach = await AttachService.checkAttachments(isbn);
        if (!resultAttach.status) {
            return;
        }
        setHasAttach(resultAttach.data.data[0].has_contents);

        BookService.getIndex(isbn, (res) => {
            loader.setLoader(false);
            if (res.outline) {
                setIndex(res.outline);
            }
        });

        resultBook.data.aipenFaqs && setFaqs(resultBook.data.aipenFaqs.filter((a: IFaq) => a.pubblicata));
        resultBook.data.aipenBookReview && setOverview(resultBook.data.aipenBookReview.overview);
        
        if(resultBook.data.aipenInitStatus && resultBook.data.aipenInitStatus === "COMPLETED") {
            setAipenID(resultBook.data.aipenDocumentiId);

            if (!userIsLogged) {
                return;
            }
            getQAInBook(resultBook.data.aipenDocumentiId).then(res => {
                if (res.status === 200) {
                    Array.isArray(res.data) && setQuestions(res.data);
                }
            })
        }
    }

    const uploadTitle = () => {
        const path: string[] = slug.split("-");
        path.pop();
        document.title = `${path.join(" ")} | Biblioteca digitale Maggioli`;
    };

    const openIlibro = () => {
        if (!book.hasAccessToExtraContent) {
            setShowModal(true);
        } else {
            setShow(true);
        }
    };

    const onClickAttachments = () => {
        if (!book.hasAccessToExtraContent) {
            setShowModal(true);
        } else {
            setModalVisibile(true);
        }
    };

    const hasIlibro = (): boolean => {
        return book !== null && book.formati !== undefined && book.formati.includes("iLibro");
    };

    const bookTitle = (): string => {
        const title = book !== null ? book.name : "";
        return hasIlibro() ? `iLibro - ${title}` : title;
    };

    const onClickGenerateAnswer = async () => {

        if(!userIsLogged || !book.hasAccessToExtraContent) {
            setShowModal(true);
            return;
        }

        if(newQuestion.length < 10) {
            alert("La domanda deve essere lunga almeno 10 caratteri.");
            return;
        }


        const userChecked = localStorage.getItem("aipenuser") !== null;
        if(!userChecked) {
            const checkUserRes = await checkUser();
            if (checkUserRes.status) {
                localStorage.setItem("aipenuser", "true");
            }
        }

        const payload = {
            task: "q&a",
            args: {
                language: "it",
                question: newQuestion,
            },
        };
        setGenerating(true);
        askQuestionJob(payload, aipenID).then(res => {
            if (!res.data.status) {
                setGenerating(false);
                alert("Errore nella generazione della risposta");
                return;
            }
            setTimeout(() => {
                getQAInBook(aipenID).then(res2 => {
                    setGenerating(false);
                    if (res2.status !== 200 || !Array.isArray(res2.data)) {
                        return;
                    }
                    setQuestions(res2.data);
                    setInfoText(questions.length === res2.data.length);  
                })
            }, 5000);

        })
    }

    const onClickOpenFaq = async () => {
        if(!userIsLogged) {
            setShowModal(true);
            return;
        }
        setShowFAQs(true);
    }

    const onClickCopyAnswerText = (e, text: string) => {
        e.stopPropagation();
        navigator.clipboard.writeText(text);
        setCopy(true);
        setTimeout(() => setCopy(false), 2000);
    }

    const btnIcon: string = hasIlibro() ? "logo-ilibro" : "";

    const description: string = book !== null && book.description !== undefined ? book.description.replace(/<strong>/gi, "<br/><strong>") : "";

    const isNew = book !== null ? book.mese >= new Date().getMonth() && book.anno === new Date().getFullYear() : "";

    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            {book !== null && !loader.visible && (
                <div className="view-limit pt-8 pb-0 lg:pb-8">
                    <Grid template="book" gutter="xlarge">
                        <div className="pb-10 border-b-2 border-adjust-tone-17 lg:pb-5 lg:border-0">
                            <Grid className="sticky top-32">
                                <div>
                                    <Button icon="action-back" variant="secondary-outline" onClick={() => history(-1)} horizontalPadding="small">
                                        Torna indietro
                                    </Button>
                                </div>
                                {/* Copertina del volume */}
                                <div>
                                    <BookCover data={book} bookPage />
                                    {isNew && <BookLabel className="w-full z-10" />}
                                </div>

                                {/* Formato */}
                                <Grid className="gap-0">
                                    <H5>Formato</H5>
                                    <Caption>Digitale / Volume fisico</Caption>
                                </Grid>

                                {/* ISBN */}
                                <Grid className="gap-0">
                                    <Detail><strong>ISBN</strong></Detail>
                                    <Caption>{book.isbn}</Caption>
                                </Grid>

                                {/* Numero di pagine (se c'è) */}
                                {book.numberOfPages > 0 && (
                                    <Grid className="gap-0">
                                        <Detail><strong>Pagine</strong></Detail>
                                        <Caption>{book.numberOfPages}</Caption>
                                    </Grid>
                                )}

                                {/* <Grid className="gap-0">
                                    <Detail><strong>Area</strong></Detail>
                                    {book.categoria.map((c, i) => {
                                        const includedAreas = areas.filter(a => a.areaLabel.includes(c))[0];
                                        if (includedAreas === undefined) {
                                            return;
                                        }
                                        
                                        return <Caption key={i} className={includedAreas.color + " mb-1"}>{c} </Caption>
                                    })}
                                </Grid> */}
                            </Grid>
                        </div>
                        <Grid rows="fit-vertically gap-10">

                            {/* Descrizione del volume */}
                            <Grid className="gap-1">
                                {/* Titolo volume */}
                                <H2>{book.name}</H2>
                                { overview === "" && <Detail className="content-description text-justify">
                                    <span dangerouslySetInnerHTML={{ __html: description }} />
                                </Detail> }
                            </Grid>

                            { overview !== "" && 
                            <Grid className="gap-1">
                                <H3 className="items-center inline-flex gap-2">Riassunto <AIGenerated/></H3>
                                    <Detail class="text-justify text-base">{overview}</Detail>
                            </Grid> }
                            
                            {  aipenID !== "" && <div>
                                <Grid className={`rounded-2xl transition-all gap-1 p-0 bg-transparent`}>

                                    <H3 className="items-center inline-flex gap-2">Fai una domanda al volume<AIGenerated/></H3>
                                    { searchbar(newQuestion, setNewQuestion, questions.length.toString(), placeholder) }
                                    {/* <InputText value={newQuestion} onChange={(e: ChangeEvent<HTMLFormElement>)=> setNewQuestion(e.target.value)} icon="data-search" label="" 
                                                placeholder={placeholder} key={questions.length}/> */}
                                    
                                </Grid>
                                <div className="pt-3">
                                    <Button onClick={() => onClickGenerateAnswer()} disabled={generating}>
                                        { generating ? "Sto cercando la risposta..." : "Invia domanda" }
                                    </Button>
                                </div>
                            </div> }
                            { infoText && 
                            <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail">
                                La generazione della risposta sta richiedendo più tempo del previsto. 
                                Ti invitiamo a tornare su questa pagina tra qualche secondo.
                            </Banner> }

                            <Accordion>
                                { questions.map((question, i) => {
                                    return <AccordionItem title={question.question} key={i}>
                                        <ReactMarkdown className="text-lg">
                                            {question.answer}
                                        </ReactMarkdown>
                                        <Row lastChild="to-right">
                                            <div className={'mt-3 cursor-pointer inline-flex items-center gap-2 bg-adjust-tone-19 rounded-full px-4 py-1.5'} 
                                                onClick={e => onClickCopyAnswerText(e, question.answer)}>
                                                <div className="bg-adjust-tone-04 rounded-full inline-flex items-center justify-center w-6 h-6 text-adjust-tone">
                                                    <Icon name={copy ? "status-success" : "action-copy"} size="small"></Icon>
                                                </div>
                                                <Caption>
                                                    { copy ? "Testo copiato!" : "Copia testo" }
                                                </Caption>
                                            </div>
                                            <div className="mt-3"><AIGenerated/></div>
                                        </Row>
                                    </AccordionItem>
                                })}
                            </Accordion>
                        </Grid>

                        <div className="pb-10 lg:pb-5">
                            <Grid className="sticky top-32 gap-8">
                                <Grid className="gap-4">
                                    {/* Link scopri il servizio (visibile solo se non loggati) */}
                                    {!book.hasAccessToExtraContent && (
                                        <Link to="/servizio">
                                            <Button className="justify-start" width="fill" icon="document-book" variant="secondary-outline">
                                                Scopri il servizio
                                            </Button>
                                        </Link>
                                    )}

                                    {/* Bottone di visualizzazione dell'indice del volume */}
                                    {index !== "" ? (
                                        <Button className="justify-start" variant="secondary-outline" icon="data-view" onClick={() => setShowIndex(true)}>
                                            Vedi indice
                                        </Button>
                                    ) : null}

                                    { faqs.length > 0 &&
                                    <Button className="justify-start" variant="secondary-outline" icon="head-question" onClick={() => onClickOpenFaq()}>
                                        FAQ
                                    </Button> }

                                    {/* Bottone di consultazione del volume (iLibro se disponibile) */}
                                    <Button className="justify-start" icon={btnIcon} variant={ btnIcon ? 'primary' : 'secondary'} onClick={() => openIlibro()}>
                                        Consulta il volume
                                    </Button>

                                    {/* Bottone contenuti aggiuntivi */}
                                    {hasAttach && (
                                        <Button className="justify-start" icon="additional-contents" variant="secondary" onClick={() => onClickAttachments()}>
                                            Contenuti aggiuntivi
                                        </Button>
                                    )}
                                </Grid>
                                <Grid className="gap-4">
                                    {/* Autori */}
                                    {book.autori !== undefined && (
                                        <Grid className="gap-0">
                                            <Detail><strong>Autori</strong></Detail>
                                            {book.autori.map((elem, index) => (
                                                <Caption key={index}>{elem}</Caption>
                                            )) }
                                        </Grid>
                                    )}

                                    {/* Anno e mese di pubblicazione */}
                                    <Grid className="gap-0">
                                        <Detail><strong>Pubblicazione</strong></Detail>
                                        <Caption>{`${Months.getName(book.mese)} ${book.anno}`}</Caption>
                                    </Grid>

                                    {/* Collana volume */}
                                    <Grid className="gap-0">
                                        <Detail><strong>Collana</strong></Detail>
                                        <Caption>{book.collana}</Caption>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Modal visible={showIndex} onCancel={() => setShowIndex(false)} footer={false}>
                        <H3 className="mb-3">Indice</H3>
                        <Detail
                            className="cursor-pointer"
                            onClick={() => {
                                setShowIndex(false);
                                openIlibro();
                            }}
                        >
                            <p dangerouslySetInnerHTML={{ __html: index }}></p>
                        </Detail>
                    </Modal>

                    <Modal visible={showFAQs} onCancel={() => setShowFAQs(false)} footer={false}>
                        <Grid className="gap-4">
                            <H3 className="items-center inline-flex gap-2">FAQ <AIGenerated/></H3>
                            <Accordion>
                                { faqs.map((f, i) => {
                                    return <AccordionItem title={f.domanda} key={i}>
                                        <Detail>{f.risposta}</Detail>
                                    </AccordionItem>
                                })}
                            </Accordion>
                        </Grid>
                    </Modal>

                    {/* Modale consultazione volume */}
                    <ILibro show={show} setShow={setShow} isbn={book.isbn} title={bookTitle()} cache={Date.now()} viewer={book.viewer} />

                    {/* Modale contenuti aggiuntivi */}
                    <Attachments
                        redazionale={redazionale}
                        loader={loader}
                        idProdotti={idProdotti}
                        key={book ? book.isbn : 0}
                        book={book}
                        visible={modalVisibile}
                        hideModal={() => setModalVisibile(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default BookPage;
