import React from 'react'
// import clsx from 'clsx'
import { Caption, Icon } from '@maggioli-design-system/react'
import { useNavigate } from 'react-router-dom'

const description: string = "Questo contenuto è generato tramite strumenti di Intelligenza Artificiale e potrebbe non essere sempre esatto e fedelmente rappresentativo del testo. Considera di verificare le informazioni che ritieni importanti.";

const AIGenerated = ({ className = "", ...restProps }) => {

    const router = useNavigate()

    const onClick = () => {
        router("/ai-policy");
    }

    return <div className={className + ' cursor-pointer inline-flex items-center gap-2 bg-adjust-tone-19 rounded-full px-4 pl-1.5 py-1.5'} title={description} onClick={() => onClick()}>
        <div className="bg-adjust-tone-04 rounded-full inline-flex items-center justify-center w-6 h-6 text-adjust-tone">
            <Icon name="ai-brain" size="small"></Icon>
        </div>
        <Caption>
            AI Generated
        </Caption>
    </div>
}
export default AIGenerated
