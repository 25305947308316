import {
    H3,
    H4
} from '@maggioli-design-system/react'
import { useEffect } from 'react'
import { scrollTop } from '../lib/Functions'

export default function AIPolicy(): JSX.Element {

    useEffect(() => {
        scrollTop()
    }, [])

    return (
        <div className="px-8 background-color-adjust-tone-19">
            <div className="padding-normal view-limit text-conditions">
                <H3 className="text-center vertical-padding-xxsmall">Istruzioni per l’uso dell’IA</H3>
                <div>
                    <H4>Natura del sistema AI e dei contenuti</H4>
                    Il sistema di intelligenza artificiale utilizzato in questo prodotto è progettato per interrogare e
                    generare risposte basate sui contenuti del libro.
                    Sebbene l&#39;intelligenza artificiale sia stata addestrata per fornire informazioni accurate e pertinenti,
                    i risultati ottenuti potrebbero non essere sempre esatti o completamente rappresentativi del testo
                    originale.
                </div>
                <div className="mt-5">
                    <H4>Verifica e consultazione del testo originale</H4>
                    Le informazioni fornite dal sistema sono da considerarsi di supporto e non sostitutive del
                    contenuto originale del libro.
                    Si raccomanda di consultare direttamente il testo per approfondimenti e per verificare eventuali
                    dettagli normativi, legali o tecnici.
                </div>
                <div className="mt-5">
                    <H4>Limiti interpretativi dell&#39;intelligenza artificiale</H4> 
                    Le risposte generate dal sistema AI sono basate su algoritmi che interpretano il contenuto del
                    libro.
                    L&#39;intelligenza artificiale potrebbe non essere in grado di cogliere sfumature, contesti complessi o
                    significati impliciti che richiederebbero una lettura critica da parte dell&#39;utente.
                </div>
                <div className="mt-5">
                    <H4>Non sostituzione del parere esperto</H4> 
                    Il sistema di intelligenza artificiale non sostituisce la consulenza di esperti qualificati, soprattutto in
ambiti specialistici come il diritto.
Le risposte fornite non costituiscono pareri professionali o legali.
                </div>
                <div className="mt-5">
                    <H4>Aggiornamenti del libro e modifiche normative</H4>
                    Le informazioni fornite dall&#39; intelligenza artificiale sono basate sul contenuto del libro nella sua
                    versione attuale. Eventuali aggiornamenti o modifiche successive non saranno automaticamente
                    riflessi nelle risposte generate. È responsabilità dell&#39;utente verificare se il testo originale è stato
                    aggiornato o se sono intervenute modifiche normative.
                </div>
                <div className="mt-5">
                    <H4>Utilizzo responsabile delle risposte</H4>
                    L&#39;uso delle informazioni fornite dal sistema AI è a esclusivo rischio dell&#39;utente.
                    Le risposte devono essere utilizzate come spunti di riflessione o punti di partenza per ulteriori
                    ricerche e non come informazioni definitive o vincolanti.
                </div>
                <div className="mt-5">
                    <H4>Privacy e trattamento dei dati</H4>
                    Il sistema AI è progettato per interrogare i contenuti del libro e non raccoglie o utilizza dati
                    personali degli utenti durante l&#39;interazione.
                    Qualsiasi eventuale trattamento di dati avverrà in conformità con le leggi vigenti in materia di
                    protezione dei dati personali.
                </div>
                <div className="mt-5">
                    <H4>Responsabilità</H4>
                    L&#39;utente riconosce e accetta che l&#39;utilizzo dei servizi e delle applicazioni di intelligenza artificiale
                    forniti è a proprio rischio. L&#39;azienda non è responsabile di eventuali danni diretti o indiretti
                    derivanti dall&#39;uso di tali servizi, inclusi errori, omissioni o interpretazioni errate delle informazioni
                    generate dall&#39;AI.
                    L&#39;utente è responsabile della verifica e della validazione delle informazioni ottenute e di qualsiasi
                    decisione presa sulla base di esse.
                </div> 
                    <div className='mt-5'>
                        <H4>Condivisione e pubblicazione di contenuti generati dalla IA</H4>
                        In caso di condivisione o pubblicazione dei contenuti, occorre indicare che il contenuto è generato dall'intelligenza artificiale, in un modo che i terzi destinatari possano ragionevolmente avvedersene.
                    </div>
                <br/>
            </div>
        </div>
    )
}

