import React from 'react'
import { Card, H3 } from '@maggioli-design-system/react'
import FreeBook from './FreeBook';

const ExampleBook = ({ className = "", ...restProps }) =>
  <div className={className + ' flex flex-col gap-4 items-center justify-center'}>
    <Card className="max-w-md">
      <H3 className="text-xl">Consulta un volume</H3>
      <FreeBook iLibro={true} />
    </Card>
  </div>

export default ExampleBook
